@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$mobileWidth: 88px;
$desktopWidth: 150px;
$horizontalOffset: -$desktopWidth - 15;
$horizontalOffsetMobile: -$mobileWidth - 8;

.root {
  margin-bottom: 32px;
  text-align: center;
  .headerTextRoot {
    display: flex;
    justify-content: center;
    overflow: hidden;

    h2 {
      margin: 0;
      padding: 0;
      display: inline-flex;
      justify-content: center;
      text-align: center;
      max-width: 70%;
    }
    
    .headerText {
      display: inline-block;
      position: relative;
      text-align: center;
      color: $snow;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0.72px;
      text-transform: uppercase;
      font-size: 26px;
      
      @include media-breakpoint-up(md) {
        font-size: 36px;
      }
      
      &:before, &:after {
        position: absolute;
        background: url('/images/components/FlourishHeader/header-flourish.svg') no-repeat 0 0;
        background-size: 98% auto;
        content: '';
        transform: translate(0, -50%);
        bottom: 3px;
        width: $mobileWidth;
        height: 10px;

        @include media-breakpoint-up(md) {
          width: $desktopWidth;
          height: 12px;
          bottom: 8px;
        }
      }

      &:before {
        transform: translate(0, -50%) scaleX(-1);
        left: $horizontalOffsetMobile;

        @include media-breakpoint-up(md) {
          left: $horizontalOffset;
        }
      }

      &:after {
        right: $horizontalOffsetMobile;

        @include media-breakpoint-up(md) {
          right: $horizontalOffset;
        }
      }
    }

    .subText {    
      font-family: $sofiapro;
      font-size: 18px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0.3px;
      text-align: center;
    
      @include media-breakpoint-up(md) {
        margin: 0 0 15px 0;
      }
    }
  }

  &.light {
    .headerTextRoot {
      .headerText {
        &:before, &:after {
          filter: invert(1);
        }
      }
      h2 span {
        color: $ww-black;
      }
    }
    .subText {
      color: $ww-black;
    }
  }
}
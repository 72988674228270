@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: $component-max-width;
}

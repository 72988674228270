@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

$hover-time: 0.23s;
$hover-type: ease-in;

.container {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  position: relative;
  &.third {
    flex-basis: 33%;
  }

  .completedAnimation {
    position: absolute;
    width: 120%;
    height: 120%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 4;
  }

  @include media-breakpoint-down(lg) {
    flex-basis: 42%;

    &.third {
      flex-basis: 42%;
    }
  }

  &:not(.completed):hover {
    .startImage {
      background-size: 90%;
      cursor: pointer;

      & > picture {
        transform: scale(1.1);
      }
    }
  }

  &.completed:hover {
    .completedImage {
      background-size: 120%;
    }
  }
}

.completedImage,
.startImage {
  width: 100%;
  position: relative;
  aspect-ratio: 1/1;
  background-image: var(--explosion-bg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-size $hover-time $hover-type;

  & > picture {
    transition: transform $hover-time $hover-type;
  }
}

.completedImage {
  background-size: 80%;
  transition: background-size ($hover-time - 0.05) $hover-type;
  @include media-breakpoint-down(lg) {
    margin-bottom: 10px;
  }
}

.startCta {
  margin: 0 auto;
  width: 100%;
}

.startingCtaLink {
  width: 100%;
  display: flex;
  justify-content: center;
}

.buttonOverride {
  margin: 0 auto;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: 12px !important;
  width: 100%;
  @include media-breakpoint-down(lg) {
    font-size: 11px !important;
    padding: 10px 20px 12px !important;
    min-height: 54px;
    width: 90%;
  }
}

.completedDetails {
  position: relative;
  text-align: center;
  z-index: 3;
  margin-top: -15px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.reveal {
    opacity: 1;
  }
}

.completedEyebrow {
  color: $white;
  position: relative;
  text-align: center;
  font-family: $sofiapro;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.863px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.completedHeading {
  color: $white;
  position: relative;
  text-align: center;
  font-family: $harrybeastregular;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.892px;
  gap: 5px;
  margin: auto auto 10px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    gap: 8px;
    margin-bottom: auto auto 6px;
  }
}

.innerHeading {
  display: table-caption;
  position: relative;

  &:before {
    content: '';
    width: 17px;
    height: 17px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-150%, -50%);
    background: url('/images/icons/complete-check.svg') no-repeat 50% 50%;
    background-size: contain;
  }
}

.learnMore {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 150px auto 130px;
  box-sizing: border-box;
  flex-wrap: wrap;

  @include media-breakpoint-down(sm) {
    flex-basis: 40%;
    gap: 20px;
  }
}

.houseCrest {
  width: 110px;
  opacity: 0.15;
  animation: opacityPulse 2s infinite;

  &:nth-of-type(1) { animation-delay: 0s; }
  &:nth-of-type(2) { animation-delay: 0.2s; }
  &:nth-of-type(3) { animation-delay: 0.4s; }
  &:nth-of-type(4) { animation-delay: 0.6s; }

  @include media-breakpoint-down(sm) {
    width: 75px;
    flex: 0 0 calc(50% - 10px);
  }
}

@keyframes opacityPulse {
  0%, 100% { opacity:0.15; }
  50% { opacity: 0.05; }
}

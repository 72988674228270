@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

// lg breakpoint adjusts width of banner items and font sizes
// md breakpoint adjusts component level layout (stack on mobile) and sizes

$desktop-gap: 30px;
$desktop-banner-width: 110px;
$desktop-crest-width: 44px;
$tablet-gap: 10px;
$tablet-banner-width: 90px;
$tablet-crest-width: 35px;
$mobile-gap: 10px;
$mobile-banner-width: 70px;
$mobile-crest-width: 25px;

@mixin bannerFlexSettings() {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  text-align: center;
  gap: $desktop-gap;
  position: relative;
  @include media-breakpoint-down(lg) {
    gap: $tablet-gap;
  }
  @include media-breakpoint-down(md) {
    gap: $mobile-gap;
  }
}

@mixin bannerItem() {
  box-sizing: border-box;
  position: relative;
  min-width: $desktop-banner-width;
  min-height: 250px;
  @include media-breakpoint-down(lg) {
    min-width: $tablet-banner-width;
    min-height: 170px;
  }
  @include media-breakpoint-down(md) {
    min-width: $mobile-banner-width;
    min-height: 120px;
  }
}

.container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  max-width: 1440px;
  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .leftColumn,
  .rightColumn {
    box-sizing: border-box;
    padding: 0 10px;
    position: relative;

    @include media-breakpoint-down(md) {
      width: 100%;
      flex: 0 0 100%;
    }
  }
}

.leftColumn {
  flex: 0 0 60%;
  position: relative;

  .outerOuterContainer {
    position: relative;
  }

  .innerContainer {
    height: 200px;
    box-sizing: border-box;
    position: relative;
  }

  .headingOuterContainer {
    width: 100%;
    overflow: hidden;
  }

  .headingContainer {
    pointer-events: none;
    width: 100%;
    height: 90px;
    background: url(../img/sorted-today-desktop.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 3;
    margin-top: -10px;
    position: relative;

    @include media-breakpoint-down(lg) {
      top: 3px;
    }

    @include media-breakpoint-down(sm) {
      margin: -10px auto 0;
      left: 50%;
      top: 0;
      width: 120%;
      transform: translateX(-50%);
      background: url(../img/sorted-today-mobile.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  .shadowOverlay {
    background: url(../img/shadow.svg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    width: 100%;
    box-sizing: border-box;
    height: 300px;
    position: absolute;
    top: 32px;
    z-index: 2;

    .hoverBanners {
      @include bannerFlexSettings();
      gap: 0;

      @include media-breakpoint-down(lg) {
        gap: 0;
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
      
      .hoverBanner {
        cursor: pointer;
        @include bannerItem();
        min-width: $desktop-banner-width + $desktop-gap;
        @include media-breakpoint-down(lg) {
          min-width: $tablet-banner-width + $tablet-gap;
        }
      }
    }
  }
}

.goldHeading {
  color: $snow;
  font-size: 42px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  &.mobileOnly {
    text-align: center;
    display: none;
  }

  @include media-breakpoint-down(xl) {
    font-size: 38px;
  }
  @include media-breakpoint-down(lg) {
    font-size: 28px;
  }
  @include media-breakpoint-down(md) {
    &:not(.mobileOnly) {
      display: none;
    }
    &.mobileOnly {
      display: block;
    }
  }
}

.rightColumn {
  flex: 0 0 40%;
  z-index: 3;

  button {
    margin: auto;
    
    @include media-breakpoint-up(md) {
      margin: 0;
    }
  }

  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.houseResultsOuterContainer {
  position: absolute;
  top: -47px;
  left: 50%;
  transform: translateX(-50%);
  overflow-y: hidden;
  height: 275px;
  @include media-breakpoint-down(lg) {
    top: -44px;
  }
  @include media-breakpoint-down(md) {
    height: 200px;
    top: -45px;
  }
  @include media-breakpoint-down(sm) {
    top: -47px;
  }
}

.houseResultsContainer {
  @include bannerFlexSettings();

  .houseResult {
    @include bannerItem();

    @include media-breakpoint-down(md) {
      bottom: -30px;
    }

    .detailContainer {
      width: 100%;
      position: absolute;
      bottom: -30px;

      @include media-breakpoint-down(lg) {
        bottom: 5px;
      }

      @include media-breakpoint-down(md) {
        bottom: 42px;
      }

      .house {
        color: $white;
        font-family: $sofiapro;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @include media-breakpoint-down(lg) {
          font-size: 14px;
        }
      }

      .percentage {
        color: #ABA8B5;
        text-align: center;
        // Allowing the display font, because we want both characters in the number
        font-family: 'Harry Beast Display', $japaneseFallbackFonts, serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        transition: .3s ease-out;
        width: fit-content;
        margin: 0 auto;
        min-height: 33px;
        display: flex;

        &.hide {
          opacity: 0;
        }
      }
    }

    .bannerContainer {
      height: 200px;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      transition: top 1s ease-out, opacity .3s ease-out, transform .3s ease-out;

      &.isHovered {
        transform: translateY(20px);

        // banner hover states
        .banner {
          &.gryffindor {
            path { 
              stroke: $gryffindorScoreboard;
              fill: $gryffindorSecondary;
            }
          }
          &.ravenclaw {
            path { 
              stroke: $ravenclawScoreboard;
              fill: $ravenclawSecondary;
            }
          }
          &.hufflepuff {
            path { 
              stroke: $hufflepuffScoreboard;
              fill: $hufflepuffSecondary;
            }
          }
          &.slytherin {
            path { 
              stroke: $slytherinScoreboard;
              fill: $slytherinSecondary;
            }
          }
        }
      }

      &.hide {
        opacity: 0.5;
        transform: translateY(-10px);

        .percentage {
          opacity: 0;
        }
      }

      .crest {
        position: absolute;
        bottom: 54px;
        left: 50%;
        transform: translateX(-50%);
        width: $desktop-crest-width;

        @include media-breakpoint-down(lg) {
          bottom: 80px;
          width: $tablet-crest-width;
        }

        @include media-breakpoint-down(md) {
          bottom: 115px;
          width: $mobile-crest-width;
        }

        &.gryffindor {
          path {
            fill: #FEB447;
          }
        }

        &.ravenclaw {
          path {
            fill: #F1BA8C;
          }
        }

        &.hufflepuff {
          path {
            fill: #212028;
          }
        }

        &.slytherin {
          path {
            fill: #D3E9F5;
          }
        }
        
      }

      .banner {
        display: block;
        position: absolute;
        top: 16px;
        &.gryffindor {
          path { 
            fill: $gryffindorScoreboard;
            stroke: $gryffindorSecondary;
            stroke-width: 4px;
          }
        }
        &.ravenclaw {
          path {
            fill: $ravenclawScoreboard;
            stroke: $ravenclawSecondary;
            stroke-width: 4px;
          }
        }
        &.hufflepuff {
          path {
            fill: $hufflepuffScoreboard;
            stroke: $hufflepuffSecondary;
            stroke-width: 4px;
          }
        }
        &.slytherin {
          path {
            fill: $slytherinScoreboard;
            stroke: $slytherinSecondary;
            stroke-width: 4px;
          }
        }

        @include media-breakpoint-down(md) {
          top: 5px;
        }
      }
    }

  }
}
